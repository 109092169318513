export const SET_PURCHASES_LIST                         = "SET_PURCHASES_LIST";
export const SET_TOTAL_PURCHASES                        = "SET_TOTAL_PURCHASES";
export const SET_PURCHASES_LIST_ERROR                   = "SET_PURCHASES_LIST_ERROR";
export const SET_LOADING_PURCHASES                      = "SET_LOADING_PURCHASES";
export const SET_SINGLE_PURCHASE                        = "SET_SINGLE_PURCHASE";
export const SET_SINGLE_PURCHASE_ERROR                  = "SET_SINGLE_PURCHASE_ERROR";
export const SET_LOADING_SINGLE_PURCHASE                = "SET_LOADING_SINGLE_PURCHASE";
export const SET_PURCHASES_CURRENT_PAGE                 = "SET_PURCHASES_CURRENT_PAGE";
export const SET_PURCHASES_FIRST_PAGE                   = "SET_PURCHASES_FIRST_PAGE";
export const SET_PURCHASES_LAST_PAGE                    = "SET_PURCHASES_LAST_PAGE";
export const SET_PURCHASES_PREV_PAGE                    = "SET_PURCHASES_PREV_PAGE";
export const SET_PURCHASES_NEXT_PAGE                    = "SET_PURCHASES_NEXT_PAGE";
export const SET_TOTAL_PENDING_IN_PROGRESS_TRANSACTIONS = "SET_TOTAL_PENDING_IN_PROGRESS_TRANSACTIONS";
export const SET_TOTAL_ON_HOLD_TRANSACTIONS             = "SET_TOTAL_ON_HOLD_TRANSACTIONS";
export const SET_TOTAL_ASK_INFORMATION_TRANSACTIONS     = "SET_TOTAL_ASK_INFORMATION_TRANSACTIONS";
export const SET_PURCHASES_UPLOADED_DOCUMENT_LOADER     = "SET_PURCHASES_UPLOADED_DOCUMENT_LOADER";
export const SET_PURCHASES_UPLOADED_DOCUMENT_ERROR      = "SET_PURCHASES_UPLOADED_DOCUMENT_ERROR";
export const SET_PURCHASES_UPLOADED_DOCUMENT_SUCCESS    = "SET_PURCHASES_UPLOADED_DOCUMENT_SUCCESS";
export const SET_SHOW_UPLOAD_DOCUMENT_MODAL             = "SET_SHOW_UPLOAD_DOCUMENT_MODAL";
export const SET_DELETE_UPLOADED_DOCUMENT_ERROR         = "SET_DELETE_UPLOADED_DOCUMENT_ERROR";
export const SET_DELETE_UPLOADED_DOCUMENT_SUCCESS       = "SET_DELETE_UPLOADED_DOCUMENT_SUCCESS";
export const SET_IS_FILE_PICKED                         = "SET_IS_FILE_PICKED";
export const SET_MODAL_CONFIRMATION_COMMENTS            = "SET_MODAL_CONFIRMATION_COMMENTS";
export const SET_PURCHASE_COMMENTS_DATA                 = "SET_PURCHASE_COMMENTS_DATA";
export const SET_PURCHASE_COMMENTS_LOADING              = "SET_PURCHASE_COMMENTS_LOADING";
export const SET_PURCHASE_COMMENTS_ERROR                = "SET_PURCHASE_COMMENTS_ERROR";
export const SET_MEILI_PURCHASES                        = "SET_MEILI_PURCHASES";
export const SET_MEILI_TOTAL_PURCHASES                  = "SET_MEILI_TOTAL_PURCHASES";
export const SET_MEILI_TOTAL_PAGES                      = "SET_MEILI_TOTAL_PAGES";
export const SET_MEILI_PURCHASES_ERROR                  = "SET_MEILI_PURCHASES_ERROR";
export const SET_MEILI_PURCHASES_CURRENT_PAGE           = "SET_MEILI_PURCHASES_CURRENT_PAGE";
export const SET_MEILI_PURCHASES_FIRST_PAGE             = "SET_MEILI_PURCHASES_FIRST_PAGE";
export const SET_MEILI_PURCHASES_LAST_PAGE              = "SET_MEILI_PURCHASES_LAST_PAGE";
export const SET_MEILI_PURCHASES_PREV_PAGE              = "SET_MEILI_PURCHASES_PREV_PAGE";
export const SET_MEILI_PURCHASES_NEXT_PAGE              = "SET_MEILI_PURCHASES_NEXT_PAGE";
export const SET_MEIlI_RESET_PAGINATION                 = "SET_MEIlI_RESET_PAGINATION";
export const SET_IS_TEXT_COPIED                         = "SET_IS_TEXT_COPIED";