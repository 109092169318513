import {
    SET_BREAKDOWN_DATA,
    SET_LOADING_REMOTE_BREAKDOWN_DATA,
    SET_LOADING_REMOTE_SALES_STATS,
    SET_SALES_STATS,
    SET_PURCHASES_STATS,
    SET_LOADING_REMOTE_PURCHASES_STATS
} from "./rebill-breakdown.types";

const INITIAL_STATE = {
    breakdown             : [],
    loadingRebillBreakdown: false,
    salesStats            : [],
    loadingSalesStats     : false,
    purchasesStats        : [],
    loadingPurchasesStats : false
};

const rebillBreakdownReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_BREAKDOWN_DATA:
            return {
                ...state,
                breakdown: action.payload
            };
        case SET_LOADING_REMOTE_BREAKDOWN_DATA:
            return {
                ...state,
                loadingRebillBreakdown: action.payload
            };
        case SET_SALES_STATS:
            return {
                ...state,
                salesStats: action.payload
            };
        case SET_LOADING_REMOTE_SALES_STATS:
            return {
                ...state,
                loadingSalesStats: action.payload
            };
        case SET_PURCHASES_STATS:
            return {
                ...state,
                purchasesStats: action.payload
            };
        case SET_LOADING_REMOTE_PURCHASES_STATS:
            return {
                ...state,
                loadingPurchasesStats: action.payload
            };
        default:
            return state;
    }
};

export default rebillBreakdownReducer;
