export const SET_CLIENTS_LIST                   = "SET_CLIENTS_LIST";
export const SET_TOTAL_CLIENTS                  = "SET_TOTAL_CLIENTS";
export const SET_CLIENTS_LIST_ERROR             = "SET_CLIENTS_LIST_ERROR";
export const SET_LOADING_CLIENTS                = "SET_LOADING_CLIENTS";
export const SET_SINGLE_CLIENT                  = "SET_SINGLE_CLIENT";
export const SET_SINGLE_CLIENT_ERROR            = "SET_SINGLE_CLIENT_ERROR";
export const SET_LOADING_SINGLE_CLIENT          = "SET_LOADING_SINGLE_CLIENT";
export const SET_CLIENTS_CURRENT_PAGE           = "SET_CLIENTS_CURRENT_PAGE";
export const SET_CLIENTS_FIRST_PAGE             = "SET_CLIENTS_FIRST_PAGE";
export const SET_CLIENTS_LAST_PAGE              = "SET_CLIENTS_LAST_PAGE";
export const SET_CLIENTS_PREV_PAGE              = "SET_CLIENTS_PREV_PAGE";
export const SET_CLIENTS_NEXT_PAGE              = "SET_CLIENTS_NEXT_PAGE";
export const SET_CLIENTS_PAYMENTS               = "SET_CLIENTS_PAYMENTS";
export const SET_RANDOM_PASSWORD                = "SET_RANDOM_PASSWORD";
export const SET_CLIENT_NEW_EMAIL_ADDRESS       = "SET_CLIENT_NEW_EMAIL_ADDRESS";
export const SET_CLIENT_COMMENTS_DATA           = "SET_CLIENT_COMMENTS_DATA";
export const SET_CLIENT_COMMENTS_LOADING        = "SET_CLIENT_COMMENTS_LOADING";
export const SET_CLIENT_COMMENTS_ERROR          = "SET_CLIENT_COMMENTS_ERROR";
export const SET_MEILI_CLIENTS                  = "SET_MEILI_CLIENTS";
export const SET_MEILI_TOTAL_CLIENTS            = "SET_MEILI_TOTAL_CLIENTS";
export const SET_MEILI_TOTAL_CLIENTS_PAGES      = "SET_MEILI_TOTAL_CLIENTS_PAGES";
export const SET_MEILI_CLIENTS_ERROR            = "SET_MEILI_CLIENTS_ERROR";
export const SET_MEILI_CLIENTS_CURRENT_PAGE     = "SET_MEILI_CLIENTS_CURRENT_PAGE";
export const SET_MEILI_CLIENTS_FIRST_PAGE       = "SET_MEILI_CLIENTS_FIRST_PAGE";
export const SET_MEILI_CLIENTS_LAST_PAGE        = "SET_MEILI_CLIENTS_LAST_PAGE";
export const SET_MEILI_CLIENTS_PREV_PAGE        = "SET_MEILI_CLIENTS_PREV_PAGE";
export const SET_MEILI_CLIENTS_NEXT_PAGE        = "SET_MEILI_CLIENTS_NEXT_PAGE";
export const SET_MEIlI_RESET_CLIENTS_PAGINATION = "SET_MEIlI_RESET_CLIENTS_PAGINATION";
export const SET_SEND_WELCOME_EMAIL_SUCCESS     = "SET_SEND_WELCOME_EMAIL";
export const SET_SEND_WELCOME_EMAIL_ERROR       = "SET_SEND_WELCOME_EMAIL_ERROR";
export const SET_SEND_WELCOME_EMAIL_LOADING     = "SET_SEND_WELCOME_EMAIL_LOADING";
