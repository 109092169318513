import React           from 'react';
import Navigation      from "../../../components/Navigation/Navigation";
import {
    Breadcrumb,
    Card,
    Col,
    Row,
    Form,
    FormCheck
}                      from "react-bootstrap";
import {
    Link,
    withRouter
}                      from "react-router-dom";
import routerConstants from "../../../constants/router-constants";
import {connect}       from "react-redux";
import {
    getSingleWebsiteConfig,
    editWebsiteConfig
}                      from "../../../redux/WebsitesConfig/websites-config.async-actions";
import {getWebsites}   from "../../../redux/Websites/websites.async-actions";
import {useForm}       from "react-hook-form";
import ListSorter      from "../../../Utils/ListSorter";
import Loader          from '../../../components/Common/Loader';

class EditWebsitesConfig extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sideMenu: true,
            websitesData: []
        }
    }

    async componentDidMount() {
        await this.props.getSingleWebsiteConfig(this.props.match.params.websiteConfig_uuid);
        await this.props.getWebsites();

        this.setState({
            websitesData : this.props.websites.map((website) => {
                return {
                    domain: website.domain,
                    id    : website["@id"]
                }
            })
        })
    }

    _onSideMenu = (active) => {
        this.setState({sideMenu: active});
    };

    onSubmit = async (data) => {
        const websitesFilter = data.website.filter(website => website["checked"] === true);
        const preparedData = {
            enabledConfig: data.bannerActive,
            dataConfig: {
                text: data.text
            },
            websites: websitesFilter.map(website => website.id)
        }

        await this.props.editWebsiteConfig(
            this.props.match.params.websiteConfig_uuid,
            preparedData
        );

        if (this.props.successEdit) {
            this.props.history.push(routerConstants.listWebsitesConfig);
        }
    }

    WebsiteConfigForm = () => {
        const data = this?.props?.singleWebsiteConfig;

        this.props.setValue("bannerActive", data?.enabledConfig);
        this.props.setValue("slug", data["slugConfig"]);
        this.props.setValue("text", data?.dataConfig?.["text"]);

        this.state.websitesData.forEach((website, index) => {
            const isChecked = data?.website?.some(w => w["@id"] === website.id) || false;
            this.props.setValue(`website[${index}].checked`, isChecked);
        });

        return <>
            <Card>
                <Card.Body>
                    <div className="card-header">
                        <Card.Title>Edit Alert Banner</Card.Title>
                    </div>

                    <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <Form.Group>
                            <Form.Label>Active Banner Message</Form.Label>
                            <FormCheck
                                type="switch"
                                id={`bannerActive`}
                                {...this.props.register("bannerActive")}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Slug Config</Form.Label>
                            <Form.Control
                                as="input"
                                id={`slug`}
                                placeholder={"Slug"}
                                readOnly
                                {...this.props.register("slug")}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Message Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Description"
                                id={`text`}
                                {...this.props.register("text")}
                            />
                        </Form.Group>

                        <Row>
                            {
                                ListSorter
                                    .orderListByProperty(
                                        this
                                            .state
                                            .websitesData,
                                        "website.domain"
                                    )
                                    .map((website, index) => {
                                        return <Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                id={`website[${index}]`}
                                                label={website.domain}
                                                inline
                                                {...this.props.register(`website[${index}].checked`)}
                                            />
                                            <input
                                                type="hidden"
                                                value={website.id}
                                                {...this.props.register(`website[${index}].id`)}
                                            />
                                        </Form.Group>
                                    }
                                )
                            }
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    }

    render() {
        let loader = null;

        if (this.props.editLoading) {
            loader = <Loader message="Loading..." />;
        }

        return <>
            <div className="page_wrapper">
                <Navigation onClick={this._onSideMenu}/>
                <div className={`main-content d-flex flex-column ${this.state.sideMenu ? "" : "hide-sidemenu"}`}>
                    {/* Loader */}
                    {loader}
                    {/* End Loader */}

                    <div className="main-content-header">
                        <Breadcrumb>
                            <Link to={routerConstants.dashboard} className="breadcrumb-item">
                                Dashboard
                            </Link>
                            <Link to={routerConstants.listWebsitesConfig} className="breadcrumb-item">
                                Alert Banner List
                            </Link>
                            <Breadcrumb.Item active>
                                Alert Banner Edit
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    {null !== this.props.websiteError &&
                        <div className="alert alert-danger" role="alert">
                            {this.props.websiteError}
                        </div>
                    }

                    <Row>
                        <Col lg={12}>
                            {
                                this.WebsiteConfigForm()
                            }
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    }
}

const EditWebsitesConfigPage = (props) => {
    const {
        register,
        handleSubmit,
        setValue
    } = useForm();

    return <EditWebsitesConfig
        {...props}
        register={register}
        handleSubmit={handleSubmit}
        setValue={setValue}
    />;
};

const mapActionsToProps = {
    getSingleWebsiteConfig,
    editWebsiteConfig,
    getWebsites
}

const mapStateToProps = state => {
    return {
        singleWebsiteConfig: state.websitesConfig.singleWebsiteConfig,
        websites           : state.websites.set_websites,
        errorEdit          : state.websitesConfig.editWebsiteConfigError,
        successEdit        : state.websitesConfig.editWebsiteConfigSuccess,
        editLoading        : state.websitesConfig.editWebsiteConfigLoading,
        websiteLoading     : state.websitesConfig.loadingSingleWebsiteConfig,
        websiteError       : state.websitesConfig.singleWebsiteConfigError
    }
}

const EditWebsiteConfigConnected = connect(
    mapStateToProps,
    mapActionsToProps
)(EditWebsitesConfigPage);

export default withRouter(EditWebsiteConfigConnected);
