import {
    SET_DELETE_UPLOADED_DOCUMENT_ERROR,
    SET_DELETE_UPLOADED_DOCUMENT_SUCCESS,
    SET_IS_FILE_PICKED,
    SET_LOADING_PURCHASES,
    SET_LOADING_SINGLE_PURCHASE,
    SET_MEILI_PURCHASES,
    SET_MEILI_PURCHASES_CURRENT_PAGE,
    SET_MEILI_PURCHASES_ERROR,
    SET_MEILI_PURCHASES_FIRST_PAGE,
    SET_MEILI_PURCHASES_LAST_PAGE,
    SET_MEILI_PURCHASES_NEXT_PAGE,
    SET_MEILI_PURCHASES_PREV_PAGE,
    SET_MEIlI_RESET_PAGINATION,
    SET_MEILI_TOTAL_PAGES,
    SET_MEILI_TOTAL_PURCHASES,
    SET_MODAL_CONFIRMATION_COMMENTS,
    SET_PURCHASE_COMMENTS_DATA,
    SET_PURCHASE_COMMENTS_ERROR,
    SET_PURCHASE_COMMENTS_LOADING,
    SET_PURCHASES_CURRENT_PAGE,
    SET_PURCHASES_FIRST_PAGE,
    SET_PURCHASES_LAST_PAGE,
    SET_PURCHASES_LIST,
    SET_PURCHASES_LIST_ERROR,
    SET_PURCHASES_NEXT_PAGE,
    SET_PURCHASES_PREV_PAGE,
    SET_PURCHASES_UPLOADED_DOCUMENT_ERROR,
    SET_PURCHASES_UPLOADED_DOCUMENT_LOADER,
    SET_PURCHASES_UPLOADED_DOCUMENT_SUCCESS,
    SET_SHOW_UPLOAD_DOCUMENT_MODAL,
    SET_SINGLE_PURCHASE,
    SET_SINGLE_PURCHASE_ERROR,
    SET_TOTAL_ON_HOLD_TRANSACTIONS,
    SET_TOTAL_PENDING_IN_PROGRESS_TRANSACTIONS,
    SET_TOTAL_ASK_INFORMATION_TRANSACTIONS,
    SET_TOTAL_PURCHASES,
    SET_IS_TEXT_COPIED,
} from "./purchases.types";

export const setLoadingPurchasesList = loading => {
    return {
        type   : SET_LOADING_PURCHASES,
        payload: loading
    };
};

export const setTotalPurchases = total => {
    return {
        type   : SET_TOTAL_PURCHASES,
        payload: total
    };
};

export const setPurchasesListError = error => {
    return {
        type   : SET_PURCHASES_LIST_ERROR,
        payload: error
    };
};

export const setPurchasesList = purchases => {
    return {
        type   : SET_PURCHASES_LIST,
        payload: purchases
    };
};

export const setLoadingSinglePurchase = loading => {
    return {
        type   : SET_LOADING_SINGLE_PURCHASE,
        payload: loading
    };
};

export const setSinglePurchase = purchase => {
    return {
        type   : SET_SINGLE_PURCHASE,
        payload: purchase
    };
};

export const setSinglePurchaseError = purchase_error => {
    return {
        type   : SET_SINGLE_PURCHASE_ERROR,
        payload: purchase_error
    };
};

export const setPurchasesCurrentPage = currentPage => {
    return {
        type   : SET_PURCHASES_CURRENT_PAGE,
        payload: currentPage
    };
};

export const setPurchasesFirstPage = firstPage => {
    return {
        type   : SET_PURCHASES_FIRST_PAGE,
        payload: firstPage
    };
};

export const setPurchasesLastPage = lastPage => {
    return {
        type   : SET_PURCHASES_LAST_PAGE,
        payload: lastPage
    };
};

export const setPurchasesNextPage = lastPage => {
    return {
        type   : SET_PURCHASES_NEXT_PAGE,
        payload: lastPage
    };
};

export const setPurchasesPrevPage = prevPage => {
    return {
        type   : SET_PURCHASES_PREV_PAGE,
        payload: prevPage
    };
};

export const setTotalPendingInProgressTransactions = total => {
    return {
        type   : SET_TOTAL_PENDING_IN_PROGRESS_TRANSACTIONS,
        payload: total
    };
};

export const setTotalOnHoldTransactions = total => {
    return {
        type   : SET_TOTAL_ON_HOLD_TRANSACTIONS,
        payload: total
    };
};

export const setTotalAskInformationTransactions = total => {
    return {
        type   : SET_TOTAL_ASK_INFORMATION_TRANSACTIONS,
        payload: total
    };
}

export const setPurchasesUploadedDocumentLoader = loading => {
    return {
        type   : SET_PURCHASES_UPLOADED_DOCUMENT_LOADER,
        payload: loading
    };
};

export const setPurchasesUploadedDocumentError = error => {
    return {
        type   : SET_PURCHASES_UPLOADED_DOCUMENT_ERROR,
        payload: error
    };
};

export const setPurchasesUploadedDocumentSuccess = success => {
    return {
        type   : SET_PURCHASES_UPLOADED_DOCUMENT_SUCCESS,
        payload: success
    };
};

export const setShowUploadDocumentModal = modal => {
    return {
        type   : SET_SHOW_UPLOAD_DOCUMENT_MODAL,
        payload: modal
    };
};

export const setDeleteUploadDocumentError = error => {
    return {
        type   : SET_DELETE_UPLOADED_DOCUMENT_ERROR,
        payload: error
    };
};

export const setDeleteUploadDocumentSuccess = success => {
    return {
        type   : SET_DELETE_UPLOADED_DOCUMENT_SUCCESS,
        payload: success
    };
};

export const setIsFilePicked = bool => {
    return {
        type   : SET_IS_FILE_PICKED,
        payload: bool
    };
};

export const setConfirmationComments = comments => {
    return {
        type   : SET_MODAL_CONFIRMATION_COMMENTS,
        payload: comments
    };
};

export const setPurchaseCommentsData = data => {
    return {
        type   : SET_PURCHASE_COMMENTS_DATA,
        payload: data
    };
};

export const setPurchaseCommentsLoading = loading => {
    return {
        type   : SET_PURCHASE_COMMENTS_LOADING,
        payload: loading
    };
};

export const setPurchaseCommentsError = error => {
    return {
        type   : SET_PURCHASE_COMMENTS_ERROR,
        payload: error
    };
};

export const setMeiliPurchases = purchases => {
    return {
        type   : SET_MEILI_PURCHASES,
        payload: purchases
    };
};

export const setMeiliTotalPurchases = total => {
    return {
        type   : SET_MEILI_TOTAL_PURCHASES,
        payload: total
    };
};

export const setMeiliPurchasesError = error => {
    return {
        type   : SET_MEILI_PURCHASES_ERROR,
        payload: error
    };
};

export const setMeiliPurchasesCurrentPage = currentPage => {
    return {
        type   : SET_MEILI_PURCHASES_CURRENT_PAGE,
        payload: currentPage
    };
};

export const setMeiliTotalPages = totalPages => {
    return {
        type   : SET_MEILI_TOTAL_PAGES,
        payload: totalPages
    };
};

export const setMeiliPurchasesFirstPage = firstPage => {
    return {
        type   : SET_MEILI_PURCHASES_FIRST_PAGE,
        payload: firstPage
    };
};

export const setMeiliPurchasesLastPage = lastPage => {
    return {
        type   : SET_MEILI_PURCHASES_LAST_PAGE,
        payload: lastPage
    };
};

export const setMeiliPurchasesPreviousPage = previousPage => {
    return {
        type   : SET_MEILI_PURCHASES_PREV_PAGE,
        payload: previousPage
    };
};

export const setMeiliPurchasesNextPage = nextPage => {
    return {
        type   : SET_MEILI_PURCHASES_NEXT_PAGE,
        payload: nextPage
    };
};

export const resetMeiliPagination = () => {
    return {
        type: SET_MEIlI_RESET_PAGINATION
    };
};

export const setIsTextCopied = bool => {
    return {
        type   : SET_IS_TEXT_COPIED,
        payload: bool
    };
};
