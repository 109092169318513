import {
    SET_BREAKDOWN_DATA,
    SET_LOADING_REMOTE_BREAKDOWN_DATA,
    SET_LOADING_REMOTE_SALES_STATS,
    SET_SALES_STATS,
    SET_PURCHASES_STATS,
    SET_LOADING_REMOTE_PURCHASES_STATS
} from "./rebill-breakdown.types";

export const setBreakdownData = data => {
    return {
        type   : SET_BREAKDOWN_DATA,
        payload: data
    };
};

export const setLoadingRemoteBreakdownData = loading => {
    return {
        type   : SET_LOADING_REMOTE_BREAKDOWN_DATA,
        payload: loading
    };
};

export const setSalesStats = data => {
    return {
        type   : SET_SALES_STATS,
        payload: data
    };
};

export const setLoadingRemoteSalesStats = loading => {
    return {
        type   : SET_LOADING_REMOTE_SALES_STATS,
        payload: loading
    };
};

export const setPurchasesStats = data => {
    return {
        type   : SET_PURCHASES_STATS,
        payload: data
    };
}

export const setLoadingRemotePurchasesStats = loading => {
    return {
        type   : SET_LOADING_REMOTE_PURCHASES_STATS,
        payload: loading
    };
}
