export const SET_SERVICES_LIST                   = "SET_SERVICES_LIST";
export const SET_LOADING_SERVICES_LIST           = "SET_LOADING_SERVICES_LIST";
export const SET_SERVICES_LIST_ERROR             = "SET_SERVICES_LIST_ERROR";
export const SET_ORDERS_SERVICES_DROP_DOWN       = "SET_ORDERS_SERVICES_DROP_DOWN";
export const SET_ORDERS_SERVICES_DROP_DOWN_ERROR = "SET_ORDERS_SERVICES_DROP_DOWN_ERROR";
export const SET_SINGLE_SERVICES                 = "SET_SINGLE_SERVICES";
export const SET_EDIT_SERVICES_ERROR             = "SET_EDIT_SERVICES_ERROR";
export const SET_EDIT_SERVICES_SUCCESS           = "SET_EDIT_SERVICES_SUCCESS";
export const SET_SINGLE_SERVICES_LOADING         = "SET_SINGLE_SERVICES_LOADING";
export const SET_CREATE_SERVICES_ERROR           = "SET_CREATE_SERVICES_ERROR";
export const SET_CREATE_SERVICES_SUCCESS         = "SET_CREATE_SERVICES_SUCCESS";
export const SET_CREATE_LOADING_SERVICES         = "SET_CREATE_LOADING_SERVICES";
