import http from "../../services/http";
import {
    setLoadingServicesList,
    setOrdersServicesDropDown,
    setOrdersServicesDropDownError,
    setServices,
    setServicesListError,
    setEditServicesError,
    setEditServicesSuccess,
    setSingleService,
    setCreateServicesError,
    setCreateServicesSuccess,
    setCreateLoadingServices,
    setSingleServiceLoading,
} from "./services.actions";

export const getServicesList = (queryParams) => {
    return async dispatch => {
        dispatch(setLoadingServicesList(true));
        dispatch(setServicesListError(null));

        try {
            const response = await http.get(
                "services",
                {
                    params: queryParams
                }
            );

            dispatch(setServices(response.data["hydra:member"]));
        } catch (e) {
            dispatch(setServices([]));
            dispatch(setServicesListError(e.message));
        } finally {
            dispatch(setLoadingServicesList(false));
        }
    };
};

export const getOrdersServicesDropDownOptions = () => {
    return async dispatch => {
        dispatch(setOrdersServicesDropDownError(null));

        try {
            const services = await http.get(
                "services?pagination=false"
            );
            let data     = services.data["hydra:member"];
            let resArr = [];

            data.filter(
                item => {
                    const i = resArr.findIndex(entry => entry.slug === item.slug);

                    if ( i === -1 ) {
                        resArr.push(item);
                    }

                    return null;
                }
            );

            dispatch(setOrdersServicesDropDown(data));
        } catch (e) {
            dispatch(setOrdersServicesDropDown([]));
            dispatch(setOrdersServicesDropDownError(e.message));
        }
    };
};

export const getSingleService = ( servicesUUID ) => {
    return async dispatch => {
        dispatch(setSingleServiceLoading(true))
        try {
            const response = await http.get(`services/${servicesUUID}`)

            dispatch(setSingleService(response.data))
            dispatch(setSingleServiceLoading(false))
        } catch (e) {
            console.dir(e)
        }
    }
}

export const updateServices = (data) => {
    const {
        slug,
        description,
        name,
        productsId,  
        websitesId,
        servicesId
    } = data;
    return async dispatch => {
        dispatch(setCreateServicesError(null));
        dispatch(setEditServicesSuccess(false));

        try {
            const response = await http.patch(
                `services/${servicesId}`,
                {
                    description        : description,
                    name               : name,
                    slug               : slug,
                    productSubscription: [
                        productsId
                    ],
                    website            : websitesId
                }
            )

            dispatch(setEditServicesSuccess(true));
            dispatch(setSingleService(response.data))

            setTimeout(
                () => dispatch(setEditServicesSuccess(null)),
                5000
            );

        } catch (e) {
            dispatch(setEditServicesError(e.message));
            dispatch(setEditServicesSuccess(false));
        }
    }
}

export const postServices = (data) => {
    const {
        slug,
        description,
        name,
        websitesId,
        productsId
    } = data;
    return async dispatch => {
        dispatch(setEditServicesError(null))
        dispatch(setCreateServicesSuccess(false))
        dispatch(setCreateLoadingServices(true))
        try {
            await http.post(
                `services`,
                {
                    description        : description,
                    name               : name,
                    slug               : slug,
                    productSubscription: [
                        productsId
                    ],
                    website            : websitesId,

                }
            )
            dispatch(setCreateLoadingServices(false))
            dispatch(setCreateServicesSuccess(true))
        } catch(e) {
            dispatch(setCreateLoadingServices(false))
            dispatch(setEditServicesError(e.message))
        }
    }
}
