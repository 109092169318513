import http from "../../../services/http";
import {
    setBreakdownData,
    setLoadingRemoteBreakdownData, setLoadingRemotePurchasesStats,
    setLoadingRemoteSalesStats,
    setPurchasesStats,
    setSalesStats
} from "./rebill-breakdown.actions";

export const getRebillBreakdownData = query => {
    return async dispatch => {
        dispatch(setLoadingRemoteBreakdownData(true));

        try {
            const rebillBreakdownResponse = await http.get(
                "payments/rebill-breakdown",
                {
                    params: query
                }
            );

            dispatch(setBreakdownData(rebillBreakdownResponse.data));
        } catch (e) {

        } finally {
            dispatch(setLoadingRemoteBreakdownData(false));
        }
    };
};

export const getSalesStatistics = query => {
    return async dispatch => {
        dispatch(setLoadingRemoteSalesStats(true));

        try {
            const rebillBreakdownResponse = await http.get(
                "payments/stats",
                {
                    params: query
                }
            );

            dispatch(setSalesStats(rebillBreakdownResponse.data));
        } catch (e) {

        } finally {
            dispatch(setLoadingRemoteSalesStats(false));
        }
    };
};

export const getPurchasesStatistics = query => {
    return async dispatch => {
        dispatch(setLoadingRemotePurchasesStats(true));

        try {
            const rebillBreakdownResponse = await http.get(
                "purchases/stats",
                {
                    params: query
                }
            );

            dispatch(setPurchasesStats(rebillBreakdownResponse.data));
        } catch (e) {

        } finally {
            dispatch(setLoadingRemotePurchasesStats(false));
        }
    };
}