import {
    SET_CLIENT_COMMENTS_DATA,
    SET_CLIENT_COMMENTS_ERROR,
    SET_CLIENT_COMMENTS_LOADING,
    SET_CLIENT_NEW_EMAIL_ADDRESS,
    SET_CLIENTS_CURRENT_PAGE,
    SET_CLIENTS_FIRST_PAGE,
    SET_CLIENTS_LAST_PAGE,
    SET_CLIENTS_LIST,
    SET_CLIENTS_LIST_ERROR,
    SET_CLIENTS_NEXT_PAGE,
    SET_CLIENTS_PAYMENTS,
    SET_CLIENTS_PREV_PAGE,
    SET_LOADING_CLIENTS,
    SET_LOADING_SINGLE_CLIENT,
    SET_MEILI_CLIENTS,
    SET_MEILI_CLIENTS_CURRENT_PAGE,
    SET_MEILI_CLIENTS_ERROR,
    SET_MEILI_CLIENTS_FIRST_PAGE,
    SET_MEILI_CLIENTS_LAST_PAGE,
    SET_MEILI_CLIENTS_NEXT_PAGE,
    SET_MEILI_CLIENTS_PREV_PAGE,
    SET_MEIlI_RESET_CLIENTS_PAGINATION,
    SET_MEILI_TOTAL_CLIENTS,
    SET_MEILI_TOTAL_CLIENTS_PAGES,
    SET_RANDOM_PASSWORD,
    SET_SINGLE_CLIENT,
    SET_SINGLE_CLIENT_ERROR,
    SET_TOTAL_CLIENTS,
    SET_SEND_WELCOME_EMAIL_SUCCESS,
    SET_SEND_WELCOME_EMAIL_ERROR,
    SET_SEND_WELCOME_EMAIL_LOADING
} from "./clients.types";

const INITIAL_STATE = {
    clientsList            : [],
    totalClients           : 0,
    clientsListError       : null,
    clientsCurrentPage     : "",
    clientsFirstPage       : "",
    clientsLastPage        : "",
    clientsPrevPage        : "",
    clientsNextPage        : "",
    loadingClientsList     : false,
    loadingSingleClient    : false,
    singleClient           : null,
    singleClientError      : null,
    orderPayments          : [],
    setRandomPassword      : "",
    clientCommentData      : [],
    clientCommentError     : null,
    clientCommentLoading   : false,
    meiliClients           : [],
    meiliTotalClients      : 0,
    meiliTotalClientsPages : 0,
    meiliClientsError      : null,
    meiliClientsCurrentPage: 0,
    meiliClientsFirstPage  : 0,
    meiliClientsLastPage   : 0,
    meiliClientsPrevPage   : 0,
    meiliClientsNextPage   : 0,
    sendWelcomeEmailSuccess: false,
    sendWelcomeEmailError  : null,
    sendWelcomeEmailLoding : false
};

const clientsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CLIENTS_LIST:
            return {
                ...state,
                clientsList: action.payload
            };
        case SET_TOTAL_CLIENTS:
            return {
                ...state,
                totalClients: action.payload
            };
        case SET_CLIENTS_LIST_ERROR:
            return {
                ...state,
                clientsListError: action.payload
            };
        case SET_LOADING_CLIENTS:
            return {
                ...state,
                loadingClientsList: action.payload
            };
        case SET_LOADING_SINGLE_CLIENT:
            return {
                ...state,
                loadingSingleClient: action.payload
            };
        case SET_SINGLE_CLIENT:
            return {
                ...state,
                singleClient: action.payload
            };
        case SET_SINGLE_CLIENT_ERROR:
            return {
                ...state,
                singleClientError: action.payload
            };
        case SET_CLIENTS_CURRENT_PAGE:
            return {
                ...state,
                clientsCurrentPage: action.payload
            };
        case SET_CLIENTS_FIRST_PAGE:
            return {
                ...state,
                clientsFirstPage: action.payload
            };
        case SET_CLIENTS_LAST_PAGE:
            return {
                ...state,
                clientsLastPage: action.payload
            };
        case SET_CLIENTS_PREV_PAGE:
            return {
                ...state,
                clientsPrevPage: action.payload
            };
        case SET_CLIENTS_NEXT_PAGE:
            return {
                ...state,
                clientsNextPage: action.payload
            };
        case SET_CLIENTS_PAYMENTS:
            return {
                ...state,
                orderPayments: action.payload
            };
        case SET_RANDOM_PASSWORD:
            return {
                ...state,
                setRandomPassword: action.payload
            };
        case SET_CLIENT_NEW_EMAIL_ADDRESS:
            return {
                ...state,
                singleClient: {
                    ...state.singleClient,
                    customer: {
                        ...state.singleClient.customer,
                        email: action.payload
                    }
                }
            };
        case SET_CLIENT_COMMENTS_DATA:
            return {
                ...state,
                clientCommentData: action.payload
            };
        case SET_CLIENT_COMMENTS_ERROR:
            return {
                ...state,
                clientCommentError: action.payload
            };
        case SET_CLIENT_COMMENTS_LOADING:
            return {
                ...state,
                clientCommentLoading: action.payload
            };

        case SET_MEILI_CLIENTS:
            return {
                ...state,
                meiliClients: action.payload
            };
        case SET_MEILI_TOTAL_CLIENTS:
            return {
                ...state,
                meiliTotalClients: action.payload
            };
        case SET_MEILI_CLIENTS_ERROR:
            return {
                ...state,
                meiliClientsError: action.payload
            };
        case SET_MEILI_CLIENTS_CURRENT_PAGE:
            return {
                ...state,
                meiliClientsCurrentPage: action.payload
            };
        case SET_MEILI_CLIENTS_NEXT_PAGE:
            return {
                ...state,
                meiliClientsNextPage: action.payload
            };
        case SET_MEILI_CLIENTS_LAST_PAGE:
            return {
                ...state,
                meiliClientsLastPage: action.payload
            };
        case SET_MEILI_CLIENTS_FIRST_PAGE:
            return {
                ...state,
                meiliClientsFirstPage: action.payload
            };
        case SET_MEILI_CLIENTS_PREV_PAGE:
            return {
                ...state,
                meiliClientsPrevPage: action.payload
            };
        case SET_MEILI_TOTAL_CLIENTS_PAGES:
            return {
                ...state,
                meiliTotalClientsPages: action.payload
            };
        case SET_SEND_WELCOME_EMAIL_SUCCESS:
            return {
                ...state,
                sendWelcomeEmailSuccess: action.payload
            };
        case SET_SEND_WELCOME_EMAIL_ERROR:
            return {
                ...state,
                sendWelcomeEmailError: action.payload
            };
        case SET_SEND_WELCOME_EMAIL_LOADING:
            return {
                ...state,
                sendWelcomeEmailLoding: action.payload
            };
        case SET_MEIlI_RESET_CLIENTS_PAGINATION:
            return {
                ...state,
                meiliTotalClients      : 0,
                meiliTotalClientsPages : 0,
                meiliClientsCurrentPage: 0,
                meiliClientsFirstPage  : 0,
                meiliClientsLastPage   : 0,
                meiliClientsPrevPage   : 0,
                meiliClientsNextPage   : 0
            };


        default:
            return state;
    }
};

export default clientsReducer;
