import React                        from "react";
import {Route}                      from "react-router-dom";
import Dashboard                    from "../pages/Dashboard/Dashboard";
import Login                        from "../pages/Login";
import ForgotPassword               from "../pages/ForgotPassword";
import ResetPassword                from "../pages/ResetPassword";
import Profile                      from "../pages/Profile";
import ProfileSettings              from "../pages/ProfileSettings";
import ListSingleClient             from "../pages/ListClients/ListSingleClient";
import ListPurchases                from "../pages/ListPurchases/ListPurchases";
import ListClients                  from "../pages/ListClients/ListClients";
import ListSinglePurchase           from "../pages/ListPurchases/ListSinglePurchase";
import RebillBreakdownConnected     from "../pages/Statistics/RebillBreakdown";
import SalesStatisticsConnected     from "../pages/Statistics/SalesStatistics";
import ListWebsites                 from "../pages/BusinessManagement/Websites/ListWebsites";
import EditWebsites                 from "../pages/BusinessManagement/Websites/EditWebsites";
import CreateWebsites               from "../pages/BusinessManagement/Websites/CreateWebsites";
import ListProdcutSubscription      from "../pages/BusinessManagement/ProductSubscription/ListProductSubscription";
import EditProductSubscription      from "../pages/BusinessManagement/ProductSubscription/EditProductSubscription";
import CreateProductSubscription    from "../pages/BusinessManagement/ProductSubscription/CreateProductSubscription";
import CreateProject                from "../pages/BusinessManagement/Project/CreateProject";
import ListProject                  from "../pages/BusinessManagement/Project/ListProject";
import EditProject                  from "../pages/BusinessManagement/Project/EditProject";
import CreateServices               from "../pages/BusinessManagement/Services/CreateServices";
import ListServices                 from "../pages/BusinessManagement/Services/ListServices";
import EditServices                 from "../pages/BusinessManagement/Services/EditServices";
import CreateCompany                from "../pages/BusinessManagement/Company/CreateCompany";
import ListCompany                  from "../pages/BusinessManagement/Company/ListCompany";
import EditCompany                  from "../pages/BusinessManagement/Company/EditCompany";
import SingleClientGetResetPassword from "../pages/ListClients/SingleClientGetResetPassword";
import ListUsers                    from "../pages/UserManagement/ListUsers";
import CreateUser                   from "../pages/UserManagement/CreateUser";
import UpdateUser                   from "../pages/UserManagement/UpdateUser";
import StatsOrderActive             from "../pages/Statistics/StatsOrderActive";
import ListPaymentGateway           from "../pages/BusinessManagement/PaymentGateway/ListPaymentGateway";
import EditPaymentGateway           from "../pages/BusinessManagement/PaymentGateway/EditPaymentGateway";
import ListEmails                   from "../pages/BusinessManagement/Emails/ListEmails";
import EditEmailTemplate            from "../pages/BusinessManagement/Emails/EditEmailTemplate";
import CreateEmailTemplate          from "../pages/BusinessManagement/Emails/CreateEmailTemplate";
import purchasesStatistics          from "../pages/Statistics/PurchasesStatistics";
import ListWebsitesConfig           from "../pages/BusinessManagement/WebsitesConfig/ListWebsitesConfig";
import EditWebsitesConfig           from "../pages/BusinessManagement/WebsitesConfig/EditWebsitesConfig";

import routerConstants from "../constants/router-constants";

import "../assets/css/style.scss";
import "../assets/css/responsive.scss";
import SecureRoute     from "./SecureRoute";
import router from "react-router-dom/Router";

const AppRouter = () => (
    <React.Fragment>
        <Route path={routerConstants.login} exact render={props => <Login {...props} />} />
        <Route path={routerConstants.forgotPassword} render={props => <ForgotPassword {...props} />} />
        <Route path={routerConstants.resetPassword} render={props => <ResetPassword {...props} />} />
        <SecureRoute path={routerConstants.dashboard} exact component={Dashboard} />
        <SecureRoute path={routerConstants.profile} component={Profile} />
        <SecureRoute path={routerConstants.profileSettings} component={ProfileSettings} />
        <SecureRoute path={routerConstants.listSingleClient} exact component={ListSingleClient} />
        <SecureRoute path={routerConstants.listClients} exact component={ListClients} />
        <SecureRoute path={routerConstants.listWebsites} exact component={ListWebsites} />
        <SecureRoute path={routerConstants.editWebsites} exact component={EditWebsites} />
        <SecureRoute path={routerConstants.createWebsites} exact component={CreateWebsites} />
        <SecureRoute path={routerConstants.listProductSubscription} exact component={ListProdcutSubscription} />
        <SecureRoute path={routerConstants.editProductSubscription} exact component={EditProductSubscription} />
        <SecureRoute path={routerConstants.createProductSubscription} exact component={CreateProductSubscription} />
        <SecureRoute path={routerConstants.createProject} exact component={CreateProject} />
        <SecureRoute path={routerConstants.listProject} exact component={ListProject} />
        <SecureRoute path={routerConstants.editProject} exact component={EditProject} />
        <SecureRoute path={routerConstants.createProject} exact component={CreateProject} />
        <SecureRoute path={routerConstants.listServices} exact component={ListServices} />
        <SecureRoute path={routerConstants.editServices} exact component={EditServices} />
        <SecureRoute path={routerConstants.createServices} exact component={CreateServices} />
        <SecureRoute path={routerConstants.createCompany} exact component={CreateCompany} />
        <SecureRoute path={routerConstants.listCompany} exact component={ListCompany} />
        <SecureRoute path={routerConstants.editCompany} exact component={EditCompany} />
        <SecureRoute path={routerConstants.listUsers} exact component={ListUsers} />
        <SecureRoute
            path={routerConstants.singleClientGetResetPassword}
            exact
            component={SingleClientGetResetPassword}
        />
        <SecureRoute path={routerConstants.createUser} exact component={CreateUser} />
        <SecureRoute path={routerConstants.updateUser} exact component={UpdateUser} />
        <SecureRoute path={routerConstants.statsOrderActive} exact component={StatsOrderActive} />
        <SecureRoute path={routerConstants.listPaymentGateway} exact component={ListPaymentGateway} />
        <SecureRoute path={routerConstants.editPaymentGateway} exact component={EditPaymentGateway} />
        <SecureRoute path={routerConstants.listEmails} exact component={ListEmails} />
        <SecureRoute path={routerConstants.editEmailTemplate} exact component={EditEmailTemplate} />
        <SecureRoute path={routerConstants.createNewEmailTemplate} exact component={CreateEmailTemplate} />
        <SecureRoute path={routerConstants.listWebsitesConfig} exact component={ListWebsitesConfig} />
        <SecureRoute path={routerConstants.editWebsiteConfig} exact component={EditWebsitesConfig} />

        <SecureRoute
            path={routerConstants.listPendingInProgressPurchases}
            exact
            component={ListPurchases}
        />
        <SecureRoute path={routerConstants.listSinglePurchases} exact component={ListSinglePurchase} />
        <SecureRoute path={routerConstants.rebillBreakdown} exact component={RebillBreakdownConnected} />
        <SecureRoute path={routerConstants.salesStats} exact component={SalesStatisticsConnected} />
        <SecureRoute path={routerConstants.purchasesStats} exact component={purchasesStatistics} />
    </React.Fragment>
);

export default AppRouter;
